const { windowGlobal } = require("./window-global");

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android' or null when unknown.
 */
export function getMobileOS() {
  var userAgent = windowGlobal?.navigator?.userAgent || windowGlobal?.navigator?.vendor || windowGlobal?.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return null;
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return null;
}