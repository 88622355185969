import * as React from 'react'
import { APIProvider } from '@vis.gl/react-google-maps'
import { v4 as uuidv4 } from 'uuid'

import { googleApiKey } from './config'
import { windowGlobal } from './src/utils/window-global'
import { globalSessionStorage } from './src/utils/session-storage'
import { getUrlParameters } from './src/utils/get-url-parameters'

export const wrapRootElement = ({ element }) => {
  let userId = localStorage.getItem('user_id')
  if (userId === null) {
    userId = uuidv4()
    localStorage.setItem('user_id', userId)
  }
  windowGlobal?.gtag?.('config', 'G-C7FXLK9F97', { 'user_id': userId })
  const didInitApp = parseInt(globalSessionStorage.getItem('didInitApp')) === 1
  if (!didInitApp) {
    globalSessionStorage.setItem('initialUrlParams', JSON.stringify(getUrlParameters()))
    globalSessionStorage.setItem('didInitApp', 1)
  }
  return (
    <APIProvider apiKey={googleApiKey}>
      {element}
    </APIProvider>
  )
}