import {globalSessionStorage} from "./session-storage";
import {objectToQueryString} from "./object-to-query-string";

const { windowGlobal } = require("./window-global");

export function getUrlParameters() {
  if (!windowGlobal) {
    return {}
  }

  const params = new URLSearchParams(windowGlobal.location.search);
  const paramObject = {};

  for (const [key, value] of params.entries()) {
    paramObject[key] = value;
  }

  return paramObject;
}

export function getInitialQueryString() {
  const initialParams = globalSessionStorage.getItem('initialUrlParams')

  return initialParams !== null ? `&${objectToQueryString(JSON.parse(initialParams))}` : ''
}
