import { countryCodes } from "./countries";
import { windowGlobal } from "../utils/window-global";
import { getCountryCodeFromUrl } from "../services/get-country-with-language";

export const apiHosts = {
    [countryCodes.UAE]: 'https://api.ogram.co',
    [countryCodes.GREECE]: 'https://api-gr.ogram.co',
    [countryCodes.SA]: 'https://api-sa.ogram.co',
}

export const appLinks = {
    [countryCodes.UAE]: 'https://ogram.app.link/app_uae',
    [countryCodes.GREECE]: 'https://ogram.app.link/app_greece',
    [countryCodes.SA]: 'https://ogram.app.link/app_sa',
}

export const Settings = {
    apiHost: windowGlobal
        ? apiHosts[getCountryCodeFromUrl()]
        : apiHosts[countryCodes.UAE]
}