import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'
import { supportedCountryCodesArray } from "../../../config/countries"
import { countriesLanguages } from "../../../config/countriesLanguages"
import { switchMarket } from "../../../services/switch-market"
import { getCountryIcon } from "../../../services/get-country-icon"
import { getResolvedLanguage } from "../../../services/get-language";
import { getCountryCode } from '../../../services/get-country-with-language'

const selectorCountries = supportedCountryCodesArray

export const CountryPopup = ({ visible, onClose, country, availablePages }) => {
  const { languageCode: currentLanguage } = getResolvedLanguage()

  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "country-popup"}}}) {
        nodes {
          frontmatter {
            slug
            country
            country_popup_section {
              title
              coming_soon
            }
          }
        }
      }
    }
  `)

  if (!visible) {
    return null
  }

  const savePreference = (market = country, language = currentLanguage) => {
    localStorage.setItem('didSelectMarket', 'true')
    localStorage.setItem('countryCode', market)
    localStorage.setItem('language', language)
  }

  const countryPopupSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.country_popup_section

  return (
    <div className="Country-popup">
      <div
        onClick={() => {
          savePreference()
          onClose()
        }}
        className="close-img-container"
      >
        <StaticImage
          src="../../../assets/common__close-button.png"
          alt="close"
          placeholder="blurred"
          className="close-img"
        />
      </div>
      <div className="Country-popup__content">
        <span className="Country-popup__content__title">{t(countryPopupSection.title)}</span>
        <div className="Country-popup__content__card">
          {selectorCountries.map(market => (
            <div
              onClick={() => {
                const language = countriesLanguages[market].includes(currentLanguage) ? currentLanguage : countriesLanguages[market][0]
                savePreference(market, language)
                switchMarket(market, language)
                if (getCountryCode() === market) {
                  onClose()
                }
              }}
              className={`Country-popup__content__card__item ${market === country ? '--active' : ''}`}
            >
              <img src={getCountryIcon(market)} />
              <span
                className={`Country-popup__content__card__item__title ${market === country ? '--active' : ''}`}
              >
                {t(`common.${market}`)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
