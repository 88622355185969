import * as React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import QRCode from 'react-qr-code'

import { Link } from '../link'
import { getQrLink } from '../../../utils/get-qr-link'
import { getMobileOS } from '../../../utils/get-os'
import { countryCodes } from '../../../config/countries'
import './index.sass'

const mobileOS = getMobileOS()

export function DownloadSpApp({
  popupTitle,
  popupSubtitle,
  currentCountryCode,
  qrDescription,
  storeLinks,
}) {
  const mobileStore = storeLinks.find(item => {
    if (mobileOS === 'Android') {
      return item.title === 'google play'
    } else if (mobileOS === 'iOS') {
      return item.title === 'app store'
    }
    return null
  }) ?? []
  const appLink = getQrLink(currentCountryCode)

  return (
    <div className="download-sp-app">
      <div className="download-sp-app__download-app">
        <div className="download-sp-app__download-app__left">
          <span className="download-sp-app__download-app__title">{popupTitle}</span>
          {popupSubtitle !== undefined && (
            <span className="download-sp-app__download-app__subtitle">{popupSubtitle}</span>
          )}
          <div className="download-sp-app__download-app__qr-container">
            <QRCode value={appLink} size={140} />
            <span className="download-sp-app__download-app__qr-description">{qrDescription}</span>
          </div>
          <div className="download-sp-app__download-app__stores">
            <Link
              to={appLink}
              key={mobileStore.title}
              className="download-sp-app__download-app__stores__img"
            >
              <GatsbyImage
                image={getImage(mobileStore.store_image)}
                alt={mobileStore.title}
                className="download-sp-app__download-app__stores__img"
              />
            </Link>
          </div>
        </div>
        <div className="download-sp-app__download-app__right">
          {currentCountryCode === countryCodes.UAE ? (
            <StaticImage src="../../../assets/phone-rotated-uae.png" />
          ) : (
            <StaticImage src="../../../assets/phone-rotated-gr.png" />
          )}
        </div>
      </div>
    </div>
  )
}