import React, { useContext, useState } from 'react'

import { Link } from '../../link'
import './content.sass'
import { windowGlobal } from "../../../../utils/window-global";
import { getQrLink } from '../../../../utils/get-qr-link'
import { AppContext } from "../../../../layouts";
import { DownloadSpApp } from '../../download-sp-app'
import {getInitialQueryString} from "../../../../utils/get-url-parameters";

const OPTION_CLIENT = 1
const OPTION_SP = 2

export function GetStartedContent({
  buttonsTitle,
  firstLinkAddress,
  firstButtonTitle,
  firstButtonDescription,
  secondButtonTitle,
  secondButtonDescription,
  secondLinkAddress,
  secondButtonBottomLink,
  storeLinks,
  popupTitle,
  qrTitle,
  qrDescription,
  signInTitle,
  signInDescription,
  getStarted
}) {
  const [chosenOption, setChosenOption] = useState(null)
  const [isDownloadAppMode, setIsDownloadAppMode] = useState(false)

  const { country: currentCountryCode } = useContext(AppContext)

  const trackMainButtonAnalyticsEvents = () => {
    const trackedGtagEvent = chosenOption === OPTION_CLIENT ? 'start_hiring_click' : 'start_working_click'
    const trackedFbqEvent = chosenOption === OPTION_CLIENT ? 'StartHiringClick' : 'StartWorkingClick'

    windowGlobal.gtag?.('event', trackedGtagEvent)
    if (window.location.pathname.includes('/hire')) {
      windowGlobal.fbq?.('trackCustom', trackedFbqEvent)
    }
  }

  const registerClientURL = `${secondLinkAddress}${getInitialQueryString()}`

  return (
    <div className="get-started-content__container">
      {isDownloadAppMode ? (
        <DownloadSpApp
          popupTitle={popupTitle}
          currentCountryCode={currentCountryCode}
          qrDescription={qrDescription}
          storeLinks={storeLinks}
          qrTitle={qrTitle}
        />
      ) : (
        <div className="get-started-content__content">
          <span className="title">{buttonsTitle}</span>
          <div className="buttons">
            <div onClick={() => setChosenOption(OPTION_SP)} className={`button${chosenOption === OPTION_SP ? ' active' : ''}`}>
              <div className="button__left">
                <div className="button__left__filler"></div>
              </div>
              <div className="button__right">
                <span className="button__title">{firstButtonTitle}</span>
                <span className="button__subtitle">{firstButtonDescription}</span>
              </div>
            </div>
            <div onClick={() => setChosenOption(OPTION_CLIENT)} className={`button${chosenOption === OPTION_CLIENT ? ' active' : ''}`}>
              <div className="button__left">
                <div className="button__left__filler"></div>
              </div>
              <div className="button__right">
                <span className="button__title">{secondButtonTitle}</span>
                <span className="button__subtitle">{secondButtonDescription}</span>
              </div>
            </div>
          </div>
          {chosenOption === null ? (
            <button className="submit" disabled>{getStarted}</button>
          ) : (
            <>
              <button
                className="submit mobile"
                onClick={() => {
                  trackMainButtonAnalyticsEvents()

                  window.location.href = chosenOption === OPTION_CLIENT ? registerClientURL : getQrLink(currentCountryCode)
                }}>{getStarted}</button>
              <button
                className="submit desktop"
                onClick={() => {
                  trackMainButtonAnalyticsEvents()

                  if (chosenOption === OPTION_CLIENT) {
                    window.location.href = registerClientURL
                    return
                  }

                  setIsDownloadAppMode(true)
                }}>{getStarted}</button>
            </>
          )}
          {chosenOption === OPTION_CLIENT && (
            <span className="link">{signInDescription} <Link
              to={secondButtonBottomLink}
              onClick={e => {
                windowGlobal.gtag?.('event', 'client_sign_in_click')
              }}
            >
              {signInTitle}</Link></span>
          )}
        </div>
      )}
    </div>
  )
}