import { navigate } from 'gatsby'

import { supportedCountryCodesSet } from "../../config/countries";
import { supportedLanguageCodes } from "../../config/languages";
import { windowGlobal } from "../../utils/window-global";
import { apiHosts, Settings } from '../../config/config';

export function switchMarket(country, language) {
  if (!windowGlobal) {
    return
  }
  // Update localStorage to restore the codes later
  localStorage.setItem('countryCode', country)
  localStorage.setItem('language', language)
  // Update API host
  Settings.apiHost = apiHosts[country]
  // There are not routes without country/language prefix, so country/language treated as the index page
  const indexPath = `/${country}/${language}`
  const availableMarkets = Array.from(supportedCountryCodesSet)
  const availableLanguages = Array.from(supportedLanguageCodes)
  const pathRegExp = new RegExp(`/(${availableMarkets.join('|')})/(${availableLanguages.join('|')})(/{0,1}.*)`)
  /**
   * pathRegExp outputs array with:
   * arr[0] /marketCode/languageCode
   * arr[1] marketCode
   * arr[2] languageCode
   * arr[3] subpath
   * i.g. ['/gr/en/', 'gr', 'en', '/']
   */
  const pathMatches = windowGlobal.location.pathname.match(pathRegExp)
  if (!!pathMatches) {
    const subpath = pathMatches[3]
    const searchString = windowGlobal.location.search
    navigate(`${indexPath}${subpath}${searchString ? `?${searchString}` : ''}`);
    return
  }
  windowGlobal.location.reload()
}
