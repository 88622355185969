import React, { useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { windowGlobal } from '../../../utils/window-global'
import { Header as PageHeader } from '../../common/header'
import './index.sass'

import { AppContext } from '../../../layouts'

export const DesignationHeader = ({ slug, linkColor, buttonColor, country }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            slug
            country
            hire_header_section {
              title
              subtitle
              button_title
              background_image {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              background_image_mobile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const headerSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.hire_header_section
  const { background_image, background_image_mobile } = headerSection
  const isMobile = windowGlobal.innerWidth < 1200
  const bgImg = isMobile ? background_image_mobile.childImageSharp.original.src : background_image.childImageSharp.original.src

  return (
    <div
      className="designation"
      style={{
        backgroundImage: `${isMobile ? `url(${bgImg})` : `linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent), url(${bgImg})`}`
      }}
    >
      <PageHeader linkColor={linkColor} buttonColor={buttonColor} country={country} />
      <div className="content container">
        <div className="title-container">
          <span className="title">{t(headerSection.title)}</span>
        </div>
        <div className="subtitle-container">
          <span className="subtitle">{t(headerSection.subtitle)}</span>
        </div>
        <div className="button" onClick={() => { setGetStartedPopupVisible(true) }}>
          <span className="label">{t(headerSection.button_title)}</span>
          <StaticImage
            src="../../../assets/common__arrow-right.png"
            alt=""
            placeholder="blurred"
            className="arrow-img"
          />
        </div>
      </div>
    </div>
  )
}
