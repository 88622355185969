import { appLinks } from "../config/config"
import { globalSessionStorage } from './session-storage'
import { objectToQueryString } from './object-to-query-string'
import {getInitialQueryString} from "./get-url-parameters";

export function getQrLink(countryCode) {
  const defaultLink = appLinks[countryCode]
  const userId = localStorage.getItem('user_id')
  if (userId === null) {
    return defaultLink
  }

  return `${defaultLink}?user_id=${userId}${getInitialQueryString()}`
}
